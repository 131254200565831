import { getAuth } from '@firebase/auth';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue';
import firebase from '../firebase';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/documentation',
    name: 'Documentation',
    component: () => import(/* webpackChunkName: "documentation" */ '../views/Documentation.vue'),
  },
  {
    path: '/download',
    name: 'Download',
    component: () => import(/* webpackChunkName: "download" */ '../views/Download.vue'),
  },
  {
    path: '/help',
    name: 'Help',
    component: () => import(/* webpackChunkName: "help" */ '../views/Help.vue'),
  },
  {
    path: '/profile',
    name: 'Profile',
    meta: {
      auth: true,
    },
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      guest: true,
    },
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.auth)) {
    getAuth(firebase).onAuthStateChanged((user) => {
      if (user) {
        next();
      } else {
        next({
          path: '/login',
        });
      }
    });
  } else if (to.matched.some((record) => record.meta.guest)) {
    getAuth(firebase).onAuthStateChanged((user) => {
      if (user) {
        next({
          path: '/profile',
        });
      } else {
        next();
      }
    });
  } else {
    next();
  }
});

export default router;
