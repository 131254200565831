<template>
  <div class="social">
    <iframe src="https://ghbtns.com/github-btn.html?user=VolantisDev&repo=Launchpad&type=star&count=true" frameborder="0" scrolling="0" width="90" height="20" title="GitHub"></iframe>
    <iframe src="https://ghbtns.com/github-btn.html?user=bmcclure&type=sponsor" frameborder="0" scrolling="0" width="140" height="20" title="GitHub"></iframe>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
.social
  display flex
  align-items center
  justify-content flex-end
  flex-wrap wrap
  width 100%
  margin-top 1rem
  position absolute

  img
    padding 0.5rem
</style>

<script lang="ts">
import { Vue } from 'vue-class-component';

export default class SocialLinks extends Vue {}
</script>
