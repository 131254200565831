
import { Options, Vue } from 'vue-class-component';
import IntroBox from '@/components/IntroBox.vue';

@Options({
  components: {
    IntroBox,
  },
})
export default class Home extends Vue {}
