<template>
  <div class="intro-box">
    <h3>Launch all your games from any Windows-based platform in a universally-compatible
      way (including Steam Overlay support). Take control of your games today!</h3>
    <div class="download-section">
      <router-link to="/download" class="download-button">Download Launchpad</router-link>
    </div>
    <div class="description">Launchpad is free software. It's fast and
      easy to get started.</div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
h3
  margin 40px 0 0
  font-weight normal
  font-size 1.3em
  color #C8D0BF

.download-section
  display flex
  align-items center
  justify-content center

.download-button
  margin 1em
  width 400px
  display block
  background #7A9C04
  padding 1em 2em
  color #ffffff
  text-decoration none
  text-transform uppercase
  font-weight bold
  font-size 1.2em

  &:hover
    background #89AE05
</style>

<script lang="ts">
import { Vue } from 'vue-class-component';

export default class IntroBox extends Vue {}
</script>
