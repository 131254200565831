import { createApp } from 'vue';
import { VueClipboard } from '@soerenmartius/vue3-clipboard';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import { FirebaseApp } from 'firebase/app';
import { getAuth } from '@firebase/auth';
import * as firebaseui from 'firebaseui';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import firebase from './firebase';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebaseui/dist/firebaseui.css';

const authUi = new firebaseui.auth.AuthUI(getAuth(firebase));

// firebase.analytics();

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $firebase: FirebaseApp;
    $authUi: firebaseui.auth.AuthUI;
  }
}

const app = createApp(App);

app.config.globalProperties.$firebase = firebase;
app.config.globalProperties.$authUi = authUi;

app.use(VueClipboard)
  .use(Toast)
  .use(store)
  .use(router)
  .mount('#app');
