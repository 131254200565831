
import { Options, Vue } from 'vue-class-component';
import { AccountCircle, Login, Logout } from 'mdue';
import { getAuth, User } from '@firebase/auth';

@Options({
  components: {
    AccountCircle,
    Login,
    Logout,
  },
})
export default class NavBar extends Vue {
  user: User|null = null;

  signOut(e: Event): void {
    e.stopPropagation();
    getAuth(this.$firebase).signOut();

    this.$router.push({
      name: 'Home',
    });
  }

  created() {
    getAuth(this.$firebase).onAuthStateChanged((user) => {
      this.user = user;
    });
  }
}
