<template>
  <div class="badges">
    <a href="https://github.com/VolantisDev/Launchpad/releases/latest">
      <img alt="GitHub release (latest SemVer)" src="https://img.shields.io/github/v/release/VolantisDev/Launchpad">
    </a>
    <a href="https://github.com/VolantisDev/Launchpad/releases/latest">
      <img alt="GitHub Release Date" src="https://img.shields.io/github/release-date/VolantisDev/Launchpad">
    </a>
    <a href="https://github.com/VolantisDev/Launchpad">
      <img alt="GitHub last commit" src="https://img.shields.io/github/last-commit/VolantisDev/Launchpad">
    </a>
    <a href="https://github.com/VolantisDev/Launchpad/commits/master">
      <img alt="GitHub commit activity" src="https://img.shields.io/github/commit-activity/m/VolantisDev/Launchpad">
    </a>
    <a href="https://github.com/VolantisDev/Launchpad/blob/master/LICENSE.txt">
      <img alt="GitHub license" src="https://img.shields.io/github/license/VolantisDev/Launchpad">
    </a>
    <a href="https://github.com/VolantisDev/Launchpad/releases">
      <img alt="GitHub all releases" src="https://img.shields.io/github/downloads/VolantisDev/Launchpad/total">
    </a>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
.badges
  display flex
  align-items center
  justify-content center
  flex-wrap wrap
  width 100%

  img
    padding 0.5rem
</style>

<script lang="ts">
import { Vue } from 'vue-class-component';

export default class Badges extends Vue {}
</script>
