
import { Options, Vue } from 'vue-class-component';
import NavBar from '@/components/NavBar.vue';
import Badges from '@/components/Badges.vue';
import SocialLinks from '@/components/SocialLinks.vue';

@Options({
  components: {
    NavBar,
    Badges,
    SocialLinks,
  },
})
export default class App extends Vue {}
