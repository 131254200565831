<template>
  <div class="home">
    <img class="large-logo" alt="Launchpad Logo" src="../assets/logo.png">
    <h1>Launchpad</h1>
    <h2>Game Launching Multitool</h2>
    <div class="screenshot">
      <router-link to="/about">
        <img src="https://firebasestorage.googleapis.com/v0/b/launchpad-306703.appspot.com/o/main_window.png?alt=media&token=ec2cfd39-3a52-444e-b45a-c8567bf439d9" alt="Launchpad Main Window">
      </router-link>
    </div>
    <IntroBox />
  </div>
</template>

<style lang="stylus">
  .home
    text-align: center

    .large-logo
      max-height 100px

    h1
      font-size 3em
      margin-top 0
      margin-bottom 0.25rem
      color: #fefefe

    h2
      margin-top 0
      font-size 1.2em
      font-weight normal
      margin-bottom 2rem
      color: #fefefe

    .screenshot
      display flex
      align-items center
      justify-content center

      img
        max-width 600px

</style>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import IntroBox from '@/components/IntroBox.vue';

@Options({
  components: {
    IntroBox,
  },
})
export default class Home extends Vue {}
</script>
