<template>
  <div id="nav">
    <div class="logo">
      <router-link to="/" class="nav-link">
        <img alt="Launchpad Logo" src="../assets/logo.png">
        Launchpad
      </router-link>
    </div>

    <div class="links">
      <router-link to="/download" class="nav-link">Download</router-link>
      <router-link to="/documentation" class="nav-link">Documentation</router-link>
      <router-link to="/about" class="nav-link">About</router-link>
      <router-link to="/help" class="nav-link">Help</router-link>
    </div>

    <div class="account">
      <router-link to="/profile" class="nav-link" v-show="user" title="Profile">
        <account-circle></account-circle>
      </router-link>
      <a href="#" @click.prevent="signOut" class="nav-link" v-show="user" title="Logout">
        <logout></logout>
      </a>
      <router-link to="/login" class="nav-link" v-show="!user" title="Login">
        <login></login>
      </router-link>
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
  #nav
    display flex
    align-items center
    justify-content center
    background rgb(20, 20, 20)
    background rgba(0, 0, 0, 0.5)
    padding 0.25rem 0.5rem

    .nav-link
      color #c8d0bf
      text-decoration none
      font-weight normal
      padding: 0.25rem 0.5rem

      &:hover,
      &.router-link-active
        color #fefefe

    .logo
      margin-right 1rem;

      .nav-link
        display flex
        align-items center
        font-size 1.6rem
        font-weight bold
        color #ffffff

        img
          display inline-block
          height 100%
          max-height 50px
          width auto
          margin-right 0.25em

    .links
      margin 0 1rem;
      flex-grow 1;
      display flex;

    .account
      display flex;
      margin-left 1rem;

      .nav-link
        font-size 1.6rem;
        display flex;
</style>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { AccountCircle, Login, Logout } from 'mdue';
import { getAuth, User } from '@firebase/auth';

@Options({
  components: {
    AccountCircle,
    Login,
    Logout,
  },
})
export default class NavBar extends Vue {
  user: User|null = null;

  signOut(e: Event): void {
    e.stopPropagation();
    getAuth(this.$firebase).signOut();

    this.$router.push({
      name: 'Home',
    });
  }

  created() {
    getAuth(this.$firebase).onAuthStateChanged((user) => {
      this.user = user;
    });
  }
}
</script>
