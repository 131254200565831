<template>
  <div class="app-inner">
    <header>
      <NavBar class="nav"></NavBar>
      <SocialLinks></SocialLinks>
    </header>

    <div class="launchpad-content">
      <router-view/>
    </div>
    <footer>
      <badges></badges>
    </footer>
  </div>
</template>

<style lang="stylus">
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap');

html,
body
  background #12283c
  margin 0

h1,
h2 {
  color: #fefefe
}

img
  max-width: 100%
  height auto

#app
  font-family Roboto, Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  text-align center
  background #12283c
  color #C8D0BF

a
  color white

.app-inner
  display grid
  grid-template-areas "header header header" "sidebar article ads" "footer footer footer"
  grid-template-rows: 80px 1fr 80px;
  grid-template-columns: 20% 1fr 20%;
  grid-row-gap: 10px;
  grid-column-gap: 10px;
  height: 100vh;
  margin: 0;

  @media all and (max-width: 575px)
    grid-template-areas "header" "article" "ads" "nav" "footer"
    grid-template-rows 80px 1fr 70px 1fr 70px
    grid-template-columns 1fr
</style>

<style scoped lang="stylus">
  .launchpad-content
      padding 0 1em 1em
      text-align left
      grid-area article

  header
    margin-bottom 2rem
    grid-area header

  footer
    grid-area footer
</style>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import NavBar from '@/components/NavBar.vue';
import Badges from '@/components/Badges.vue';
import SocialLinks from '@/components/SocialLinks.vue';

@Options({
  components: {
    NavBar,
    Badges,
    SocialLinks,
  },
})
export default class App extends Vue {}
</script>
